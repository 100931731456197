import { Controller } from "@hotwired/stimulus"
import { toggle } from 'el-transition'

export default class extends Controller {
  static targets = ["button", "dropdown"]

  toggle (event, shouldHide) {
    let hidden = !!shouldHide ? shouldHide : this.dropdownTarget.classList.contains("hidden")
    toggle(this.dropdownTarget)
    this.dropdownTarget.setAttribute("aria-expanded", !hidden.toString())

    hidden ? addEventListener("click", this.hide, true) : removeEventListener("click", this.hide, true)
  }

  hide = event => {
    if (!this.buttonTarget.contains(event.target)) this.toggle(true)
  }
}
